import { set } from '@shopworx/services/util/store.helper';
import { sortArray } from '@shopworx/services/util/sort.service';

// eslint-disable-next-line
const groupByKey = (list, key) => list.reduce((hash, obj) => ({...hash, [obj[key]]:( hash[obj[key]] || [] ).concat(obj)}), {})

export default ({
  namespaced: true,
  state: {
    partMatrixElement: null,
    fileData: [],
    elementdata: [],
    master: [],
    loading: null,
    noResult: null,
    modules: ['Part SOP', 'Drawing', 'Kaizen'],
    machines: [],
    submachines: [],
    parts: [],
    currentModule: null,
    assetId: null,
    selectedTab: null,
  },
  mutations: {
    setPartMatrixElement: set('partMatrixElement'),
    setFileData: set('fileData'),
    setElementData: set('elementdata'),
    setMaster: set('master'),
    setLoading: set('loading'),
    setNoResult: set('noResult'),
    setModules: set('modules'),
    setCurrentModule: set('currentModule'),
    setAssetId: set('assetId'),
    setMachines: set('machines'),
    setSubMachines: set('submachines'),
    setParts: set('parts'),
    setSelectedTab: set('selectedTab'),
  },
  actions: {
    fetchModules: async ({ commit, dispatch }) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'sopcategories',
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setModules', records);
        return true;
      }
      return false;
    },
    postFileData: async ({ commit, dispatch }, { filePayload, elementData }) => {
      const records = await dispatch(
        'element/uploadFiles',
        {
          elementName: 'filemaster',
          payload: filePayload,
        },
        { root: true },
      );
      if (records && records.id) {
        dispatch('updateData', { id: records.id, elementData });
      } else {
        commit('helper/setAlert', {
          show: true,
          type: 'error',
          message: 'ERROR_UPLOADING_FILE',
        }, {
          root: true,
        });
      }
    },
    updateData: async ({ commit, dispatch, state }, { id, elementData }) => {
      const { currentModule, selectedTab } = state;
      const { machinename, partname } = elementData;
      // const { attributes } = currentModule;
      const payload = {
        partname,
        machinename,
        type: selectedTab,
        module: currentModule,
      };
      // attributes.forEach((atr) => {
      //   payload[atr] = elementData[atr];
      // });
      const records = await dispatch(
        'element/updateRecordById',
        {
          elementName: 'filemaster',
          id,
          payload,
        },
        { root: true },
      );
      if (records) {
        commit('helper/setAlert', {
          show: true,
          type: 'success',
          message: 'FILE_UPLOAD_SUCCESS',
        }, {
          root: true,
        });
      } else {
        commit('helper/setAlert', {
          show: true,
          type: 'error',
          message: 'ERROR_UPDATING_RECORD',
        }, {
          root: true,
        });
      }
    },
    getMaster: async ({ commit, state, dispatch }) => {
      const { assetId } = state;
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'partmatrix',
          query: `?query=assetid==${assetId}`,
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setMaster', records);
        return true;
      }
      commit('setNoResult', true);
      return false;
    },
    getMachines: async ({ commit, state, dispatch }) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'machine',
          query: `?query=assetid==${state.assetId}&sortquery=sequence==1`,
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setMachines', records);
        return true;
      }
      return false;
    },
    getSubMachines: async ({ commit, state, dispatch }) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'submachine',
          query: `?query=assetid==${state.assetId}&sortquery=sequence==1`,
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setSubMachines', records);
        return true;
      }
      return false;
    },
    getParts: async ({ commit, state, dispatch }) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'part',
          query: `?query=assetid==${state.assetId}`,
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setParts', records);
        return true;
      }
      return false;
    },
    getSOPs: async ({ dispatch }, query) => {
      const records = await dispatch(
        'element/getRecords',
        { elementName: 'filemaster', query },
        { root: true },
      );
      if (records && records.length) {
        return records;
      }
      return false;
    },
    getFile: async ({ dispatch }, downloadLink) => {
      const records = await dispatch(
        'file/downloadMedia',
        downloadLink,
        { root: true },
      );
      if (records) {
        return window.URL.createObjectURL(records);
      }
      return false;
    },
    deleteFile: async ({ dispatch }, id) => {
      const deleteParameter = await dispatch(
        'element/deleteRecordById',
        {
          elementName: 'filemaster',
          id,
        },
        { root: true },
      );
      return deleteParameter;
    },
  },
  getters: {
    partMatrixTags: ({ partMatrixElement }) => (assetId) => {
      let tags = [];
      if (partMatrixElement && assetId) {
        tags = partMatrixElement.tags.filter((tag) => tag.assetId === assetId && !tag.hide);
      }
      return tags;
    },

    machineList: ({ machines }) => {
      let machineList = [];
      if (machines && machines.length) {
        machineList = machines.map(({ machinename }) => ({ machinename }));
      }
      return machineList;
    },

    masterList: ({ master, currentModule }) => {
      if (master && master.length && currentModule) {
        // const { currentModule } = currentModule;
        const { groupBy } = currentModule;
        /** TODO Below code can be further refactored
         * (Module can be further modified for more generic product)
         * */
        /** Filter by unique machine and primary key(part,mould,model)
           * from config
           * */
        master = sortArray(master, groupBy)
          .filter((thing, index, self) => index === self
            .findIndex((t) => t.machinename === thing.machinename
          && t[currentModule.primarykey] === thing[currentModule.primarykey]));
        /** Accumulating the machines and primary key for file upload card */
        return master.reduce((acc, curr) => {
          acc[curr[groupBy]] = acc[curr[groupBy]] || [];
          acc[curr[groupBy]].push(curr);
          return acc;
        }, {});
        /** End of TODO */
      }
      return [];
    },

    machinewiseParts: ({ master, currentModule }) => {
      if (master && master.length && currentModule) {
        return groupByKey(master, 'machinename');
      }
      return [];
    },

    machinewiseSubmachines: ({ submachines, currentModule }) => {
      if (submachines && submachines.length && currentModule) {
        return groupByKey(submachines, 'machinename');
      }
      return [];
    },
  },
});
